import './App.css';

import { Switch, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { connect } from 'react-redux';

import ProtectedLayout from './components/Layout/protected-layout/protected-layout.componenet';
import PublicLayout from './components/Layout/public-layout/public-layout.componenet';
import { setIsLoggedIn } from './redux/user/user.actions';
import { setWindowWidth } from './redux/navigation/navigation.actions';
import ModalManager from './components/modal-manager/modal-manager.componenet';
import { setWindowHeight } from './redux/modal/modal.actions';
import AlertBox from './components/alertbox/alert-box.componenet';
import { authTokenSelector } from './redux/user/user.reselect';
import { createStructuredSelector } from 'reselect';
import useAccessRight from './effects/use-access-right';

const App = ({
  setIsLoggedIn,
  setWindowWidth,
  setWindowHeight,
  authToken
}) => {
  window.addEventListener('resize', (event) => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  })

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);

    const loggedInUser = localStorage.getItem('user');

    if (loggedInUser) {
      setIsLoggedIn()
    }
  }, []);

  return (
   <div className="App">
      <Switch>
        <Route path='/home' component={ProtectedLayout} />
        <Route path='/logout' component={ProtectedLayout} />
        <Route path='/help' component={ProtectedLayout} />
        <Route path='/access-denied' component={ProtectedLayout} />

        <Route path='/loggedintest' component={ProtectedLayout} />
        <Route path='/list-suppliers' component={ProtectedLayout} />
        <Route path='/detail-supplier' component={ProtectedLayout} />
        <Route path='/detail-print' component={ProtectedLayout} />
        <Route path='/list-item-taxes' component={ProtectedLayout} />
        <Route path='/detail-item-tax' component={ProtectedLayout} />
        <Route path='/list-items' component={ProtectedLayout} />
        <Route path='/detail-item' component={ProtectedLayout} />
        <Route path='/list-customer-groups' component={ProtectedLayout} />
        <Route path='/detail-customer-group' component={ProtectedLayout} />
        <Route path='/list-locations' component={ProtectedLayout} />
        <Route path='/detail-location' component={ProtectedLayout} />
        <Route path='/list-customers' component={ProtectedLayout} />
        <Route path='/detail-customer' component={ProtectedLayout} />

        <Route exact path='/company' component={ProtectedLayout} />
        <Route exact path='/company/new' component={ProtectedLayout} />
        <Route exact path='/company/:uuid' component={ProtectedLayout} />

        <Route exact path='/accounts' component={ProtectedLayout} />
        <Route exact path='/accounts/new' component={ProtectedLayout} />
        <Route exact path='/accounts/:uuid' component={ProtectedLayout} />

        <Route exact path='/suppliers' component={ProtectedLayout} />
        <Route exact path='/suppliers/new' component={ProtectedLayout} />
        <Route path='/suppliers/:uuid' component={ProtectedLayout} />

        <Route exact path='/items' component={ProtectedLayout} />
        <Route exact path='/items/new' component={ProtectedLayout} />
        <Route exact path='/items/:uuid' component={ProtectedLayout} />

        <Route exact path='/item-groups' component={ProtectedLayout} />
        <Route exact path='/item-groups/new' component={ProtectedLayout} />
        <Route exact path='/item-groups/:uuid' component={ProtectedLayout} />

        <Route exact path='/unit-of-measures' component={ProtectedLayout} />
        <Route exact path='/unit-of-measures/new' component={ProtectedLayout} />
        <Route exact path='/unit-of-measures/:uuid' component={ProtectedLayout} />

        <Route exact path='/locations' component={ProtectedLayout} />
        <Route exact path='/locations/new' component={ProtectedLayout} />
        <Route exact path='/locations/:uuid' component={ProtectedLayout} />

        <Route exact path='/customers' component={ProtectedLayout} />
        <Route exact path='/customers/new' component={ProtectedLayout} />
        <Route exact path='/customers/:uuid' component={ProtectedLayout} />

        <Route exact path='/supplier-groups' component={ProtectedLayout} />
        <Route exact path='/supplier-groups/new' component={ProtectedLayout} />
        <Route exact path='/supplier-groups/:uuid' component={ProtectedLayout} />

        <Route exact path='/customer-groups' component={ProtectedLayout} />
        <Route exact path='/customer-groups/new' component={ProtectedLayout} />
        <Route exact path='/customer-groups/:uuid' component={ProtectedLayout} />

        <Route exact path='/purchase-orders' component={ProtectedLayout} />
        <Route exact path='/purchase-orders/new' component={ProtectedLayout} />
        <Route exact path='/purchase-orders/:uuid' component={ProtectedLayout} />
        <Route exact path='/purchase-orders/:uuid/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/purchase-orders/:uuid/receiving-notes/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/purchase-orders/:uuid/purchase-returns/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/purchase-orders/:uuid/purchase-invoices/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>

        <Route exact path='/sales-orders' component={ProtectedLayout} />
        <Route exact path='/sales-orders/new' component={ProtectedLayout} />
        <Route exact path='/sales-orders/:uuid' component={ProtectedLayout} />
        <Route exact path='/sales-orders/:uuid/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/sales-orders/:uuid/invoices/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/sales-orders/:uuid/receipts/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/sales-orders/:uuid/picks/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/sales-orders/:uuid/packing-slips/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/sales-orders/:uuid/packing-lists/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/sales-orders/:uuid/box-labels/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>
        <Route exact path='/sales-orders/:uuid/credit-note/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>

        <Route exact path='/stock-transfers' component={ProtectedLayout} />
        <Route exact path='/stock-transfers/new' component={ProtectedLayout} />
        <Route exact path='/stock-transfers/:uuid' component={ProtectedLayout} />
        <Route exact path='/stock-transfers/:uuid/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>

        <Route exact path='/stock-adjustments' component={ProtectedLayout} />
        <Route exact path='/stock-adjustments/new' component={ProtectedLayout} />
        <Route exact path='/stock-adjustments/:uuid' component={ProtectedLayout} />
        <Route exact path='/stock-adjustments/:uuid/documents' noNavs={true}>
          <ProtectedLayout noNavs={true} />
        </Route>

        <Route exact path='/item-taxes' component={ProtectedLayout} />
        <Route exact path='/item-taxes/new' component={ProtectedLayout} />
        <Route exact path='/item-taxes/:uuid' component={ProtectedLayout} />

        <Route exact path='/work-orders' component={ProtectedLayout} />
        <Route exact path='/work-orders/new' component={ProtectedLayout} />
        <Route exact path='/work-orders/:uuid' component={ProtectedLayout} />

        <Route exact path='/sales-reports' component={ProtectedLayout} />
        <Route exact path='/purchase-reports' component={ProtectedLayout} />
        <Route exact path='/inventory-reports' component={ProtectedLayout} />
        

        <Route exact path='/departments' component={ProtectedLayout} />
        <Route exact path='/departments/new' component={ProtectedLayout} />
        <Route exact path='/departments/:uuid' component={ProtectedLayout} />

        <Route exact path='/designations' component={ProtectedLayout} />
        <Route exact path='/designations/new' component={ProtectedLayout} />
        <Route exact path='/designations/:uuid' component={ProtectedLayout} />

        <Route exact path='/employees' component={ProtectedLayout} />
        <Route exact path='/employees/new' component={ProtectedLayout} />
        <Route exact path='/employees/:uuid' component={ProtectedLayout} />

        <Route path='/' component={PublicLayout} />
      </Switch>
      <ModalManager />
      <AlertBox />
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setIsLoggedIn: () => dispatch(setIsLoggedIn()),
  setWindowWidth: (width) => dispatch(setWindowWidth(width)),
  setWindowHeight: (height) => dispatch(setWindowHeight(height))
})

export default connect(null, mapDispatchToProps)(App);
